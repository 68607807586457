.bt-root-lg {
  overflow: hidden;
  background: #FEFBFA;

  @media @xxl-to-more {
    .bt-main {
      width: 100%;
      overflow: hidden;
      height: calc(100vh - @nav-sub-height-xxl);
    }

    .bt-wrap {
      position: relative;
      width: 100%;
      height: calc(100vh - @nav-sub-height-xxl - @xxl-to-more-h2-margin-top - @xxl-to-more-h2-line-height);
      margin-top: 10px;
    }

    .bt-pad {
      position: absolute;
      z-index: 11;
      top: calc(50% - 0.4vw);
      left: calc(50% - 0.6vw);
      transform: translate(-50%, -50%);
      height: calc(100vh - 10px - @nav-sub-height-xxl - @xxl-to-more-h2-margin-top - @xxl-to-more-h2-line-height);
      img {
        height: calc(100vh - 10px - @nav-sub-height-xxl - @xxl-to-more-h2-margin-top - @xxl-to-more-h2-line-height);
      }
    }

    .bg {
      position: absolute;
      z-index: 1;
      bottom: 0;
      // top: calc(-@nav-sub-height-xxl - @xxl-to-more-h2-margin-top - @xxl-to-more-h2-line-height - 10px);
      width: 100vw;
      img {
        width: 100vw;
      }
    }

    .bt-layer-1 {
      position: absolute;
      z-index: 12;
      top: 50%;
      left: 50%;
      height: calc((100vh - 10px - @nav-sub-height-xxl - @xxl-to-more-h2-margin-top - @xxl-to-more-h2-line-height) / 3);
    }

    .bt-layer-2 {
      position: absolute;
      z-index: 12;
      top: 50%;
      left: 50%;
      height: calc((100vh - 10px - @nav-sub-height-xxl - @xxl-to-more-h2-margin-top - @xxl-to-more-h2-line-height) / 3);
    }

    .bt-layer-3 {
      position: absolute;
      z-index: 12;
      top: 50%;
      left: 50%;
      height: calc((100vh - 10px - @nav-sub-height-xxl - @xxl-to-more-h2-margin-top - @xxl-to-more-h2-line-height) / 3);
    }

    .bt-subject {
      position: absolute;
      height: calc((100vh - 10px - @nav-sub-height-xxl - @xxl-to-more-h2-margin-top - @xxl-to-more-h2-line-height) / 3);
      transform: scale(0.45);
      top: 50%;
      left: 50%;
    }

    .bt-subject-math {
      transform: scale(0.4) translate(-400%, -380%);
    }

    .bt-subject-chinese {
      transform: scale(0.4) translate(150%, -150%);

    }

    .bt-subject-english {
      transform: scale(0.4) translate(-400%, 60%);
    }
  }

  @media @xl-to-xxl {
    .bt-main {
      width: 100%;
      overflow: hidden;
      height: calc(100vh - @nav-sub-height-xxl);
    }

    .bt-wrap {
      position: relative;
      width: 100%;
      height: calc(100vh - @nav-sub-height-xxl - @xl-to-xxl-h2-margin-top - @xl-to-xxl-h2-line-height);
      margin-top: 10px;
    }

    .bg {
      position: absolute;
      z-index: 1;
      // top: calc(-@nav-sub-height-xxl - @xxl-to-more-h2-margin-top - @xxl-to-more-h2-line-height - 10px);
      bottom: 0;
      width: 100vw;
      img {
        width: 100vw;
      }
    }

    .bt-pad {
      position: absolute;
      z-index: 11;
      top: calc(50% - 0.4vw);
      left: calc(50% - 0.7vw);
      transform: translate(-50%, -50%);
      height: calc(100vh - 10px - @nav-sub-height-xxl - @xl-to-xxl-h2-margin-top - @xl-to-xxl-h2-line-height);
      img {
        height: calc(100vh - 10px - @nav-sub-height-xxl - @xl-to-xxl-h2-margin-top - @xl-to-xxl-h2-line-height);
      }
    }

    .bt-layer-1 {
      position: absolute;
      z-index: 12;
      top: 50%;
      left: 50%;
      height: calc((100vh - 10px - @nav-sub-height-xxl - @xl-to-xxl-h2-margin-top - @xl-to-xxl-h2-line-height) / 3);
    }

    .bt-layer-2 {
      position: absolute;
      z-index: 12;
      top: 50%;
      left: 50%;
      height: calc((100vh - 10px - @nav-sub-height-xxl - @xl-to-xxl-h2-margin-top - @xl-to-xxl-h2-line-height) / 3);
    }

    .bt-layer-3 {
      position: absolute;
      z-index: 12;
      top: 50%;
      left: 50%;
      height: calc((100vh - 10px - @nav-sub-height-xxl - @xl-to-xxl-h2-margin-top - @xl-to-xxl-h2-line-height) / 3);
    }

    .bt-subject {
      position: absolute;
      height: calc((100vh - 10px - @nav-sub-height-xxl - @xl-to-xxl-h2-margin-top - @xl-to-xxl-h2-line-height) / 3);
      transform: scale(0.45);
      top: 50%;
      left: 50%;
    }

    .bt-subject-math {
      transform: scale(0.4) translate(-400%, -380%);
    }

    .bt-subject-chinese {
      transform: scale(0.4) translate(150%, -150%);
    }

    .bt-subject-english {
      transform: scale(0.4) translate(-400%, 60%);

    }
  }
  sup {
    top: -1.4em;
    font-size: 0.4em;
  }
}

.bt-root-sm{
  background: #FEFBFA;
  width: 100%;
  height: 124.8vw;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  .bt-body {
    width: 100%;
    height: auto;
    text-align: center;
    margin-top: 1.25vw;
    img {
      width: 81.3vw;
      height: auto;
    }
  }
  sup {
    top: -1.4em;
    font-size: 0.4em;
  }
}

@hack: true; @import (reference) "/home/jenkins/agent/workspace/megrez-os-web/src/styles/base.less";