.lr-root-lg {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  .lr-title {
    font-weight: 700;
    font-size: 3.19vw;
    line-height: 3.75vw;
    letter-spacing: 0px;
    color: #000000;
    text-align: center;
  }

  .lr-flex {
    display: flex;
    flex-grow: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 5.42vw auto;

    img {
      width: 100%;
      position: relative;
      z-index: 9;
    }

    .lr-left {
      position: relative;
      width: 32vw;
      margin-right: 5.56vw;
    }

    .lr-right {
      position: relative;
      width: 32vw;
      margin-left: 5.56vw;
    }

    .lr-radio {
      position: absolute;
      z-index: 8;
      right: 7vw;
      transform-origin: 0% 0%;

      img {
        position: absolute;
        bottom: 0;
        width: 2.22vw;
      }

      &:first-of-type {
        bottom: 38.3vw;
        img{
          animation: play 3s linear infinite;
        }
        img:nth-of-type(1) {
          animation-delay: 0;
        }
        img:nth-of-type(2) {
          animation-delay: -1s;
        }
        img:nth-of-type(3) {
          animation-delay: -2s;
        }
      }
      &:last-of-type {
        bottom: 0.7vw;
        img{
          animation: playrotate 3s linear infinite;
        }
        img:nth-of-type(1) {
          animation-delay: 0;
        }
        img:nth-of-type(2) {
          animation-delay: -1s;
        }
        img:nth-of-type(3) {
          animation-delay: -2s;
        }
      }
    }
  }
}

.lr-root-sm {
  background-color: white;
  width: 100%;
  height: 137.33vw;
  overflow: hidden;

  .lr-title {
    margin-top: 8.53vw;
    font-weight: 700;
    font-size: 5.33vw;
    line-height: 6.13vw;
    letter-spacing: 0px;
    color: #000000;
    text-align: center;
    padding-top: 8.53vw;
  }
  .lr-scroll-hidden{
    width: 100%;
    height: 122.66vw;
    overflow: hidden;
  }

  .lr-scroll-wrap {
    width: 100%;
    height: 122.66vw;
    padding-bottom: 5vw;
    overflow-x: scroll;
  }

  .lr-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180.93vw;
    margin: 10vw auto 0;

    img {
      width: 100%;
      position: relative;
      z-index: 9;
    }

    .lr-left,.lr-right {
      margin: 0 3vw;
      position: relative;
      width: 80vw;
      overflow-y: visible;
    }

    .lr-radio {
      position: absolute;
      z-index: 8;
      right: 18vw;
      overflow: visible;

      img {
        position: absolute;
        bottom: 0;
        width: 5.87vw;
      }

      &:first-of-type {
        bottom: 96vw;
        img{
          animation: play 3s linear infinite;
        }
        img:nth-of-type(1) {
          animation-delay: 0;
        }
        img:nth-of-type(2) {
          animation-delay: -1s;
        }
        img:nth-of-type(3) {
          animation-delay: -2s;
        }
      }
      &:last-of-type {
        bottom: 1.9vw;
        img{
          animation: playrotate 3s linear infinite;
        }
        img:nth-of-type(1) {
          animation-delay: 0;
        }
        img:nth-of-type(2) {
          animation-delay: -1s;
        }
        img:nth-of-type(3) {
          animation-delay: -2s;
        }
      }
    }
  }
}
@keyframes play {
  0% {
    transform: scale(1.0);
    transform-origin: bottom;
    opacity: 1;
  }
  50% {
    transform: scale(3.0);
    transform-origin: bottom;
    opacity: 0.3;
  }
  100% {
    transform: scale(4.0);
    transform-origin: bottom;
    opacity: 0;
  }
}
@keyframes playrotate {
  0% {
    transform: scale(1.0) rotate(180deg);
    transform-origin: bottom;
    opacity: 1;
  }
  50% {
    transform: scale(3.0) rotate(180deg);
    transform-origin: bottom;
    opacity: 0.3;
  }
  100% {
    transform: scale(4.0) rotate(180deg);
    transform-origin: bottom;
    opacity: 0;
  }
}

@hack: true; @import (reference) "/home/jenkins/agent/workspace/megrez-os-web/src/styles/base.less";