.bn-root-lg {
  background-color: white;
  width: 100%;
  height: 38.61vw;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .bn-flex {
    display: flex;
    flex-grow: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    transform: translateY(-2vw);

    .bn-left {
      width: 43%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      img {
        width: 79%;
      }
    }

    .bn-right {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;

      .bn-wrap {
        transform: translateY(-1vw);
      }

      .bn-title {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;

        p:first-of-type {
          font-weight: 700;
          font-size: 3.19vw;
          line-height: 3.75vw;
          letter-spacing: 2px;
          color: #000000;
          margin-right: 1.11vw;
        }

        p:last-of-type {
          font-weight: 400;
          font-size: 2.08vw;
          line-height: 2.43vw;
          letter-spacing: 0px;
          color: #000000;
        }
      }

      .bn-desc {
        position: relative;
        width: 32.22vw;
        height: 1.46vw;
        font-weight: 400;
        font-size: 1.25vw;
        line-height: 1.46vw;
        color: #333333;
        margin-top: 1.11vw;
        margin-bottom: 2.36vw;
        text-align: justify;
        sup {
          position: absolute;
          top: 0.1vw;
          right: -0.4vw;
        }

        i {
          display: inline-block;
          width: 100%;
        }
        span:after {
          content:'';
          width: 100%;
          display: inline-block;
          overflow: hidden;
          height: 0;
        }
      }

      .bn-info {
        position: relative;
        left: -1vw;
        display: flex;
        width: 30vw;
        font-weight: 400;
        font-size: 1.11vw;
        line-height: 1.32vw;
        letter-spacing: 0px;
        color: #000000;
        margin-top: 0.56vw;
        transform: translateX(-0.8vw);

        &:first-of-type {
          margin-top: 2.36vw;
        }

        span:first-child {
          flex: 46%;
          text-align: right;
        }
        span:nth-child(2) {
          flex: 6%;
          text-align: center;
        }
        span:last-child {
          flex: 48%;
        }
      }

      .bn-price {
        font-weight: 500;
        font-size: 2.08vw;
        line-height: 2.43vw;
        letter-spacing: 0px;
        color: #000000;
        margin-top: 2.78vw;

        .bn-price-num {
          font-family: 'HarmonyOSNumber';
        }

        .bn-price-desc {
          font-family: 'HarmonyOSNumber';
          text-decoration-line: line-through;
          margin-left: 0.28vw;
          font-size: 1.39vw;
          line-height: 1.11vw;
          letter-spacing: 0px;
          color: #777777;
        }
      }

      .bn-btn-flex {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 1.39vw;

        button {
          border-radius: 50px;
          background: #FFFFFF;
          border: 2px solid #FF5500;
          font-weight: 500;
          font-size: 1.11vw;
          line-height: 1.32vw;
          letter-spacing: 0px;
          color: #FF5500;
          padding: 0.6vw 1.11vw;
          cursor: pointer;
        }

        button:first-of-type {
          margin-right: 0.83vw;
        }
      }
    }
  }
}

.bn-root-sm {
  margin-top: -@nav-sub-height-sm;
  background-color: white;
  width: 100%;
  height: fit-content;
  min-height: 168vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .bn-wrap {
    display: block;
    width: fit-content;
  }

  .bn-title {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    p:first-of-type {
      font-weight: 700;
      font-size: 6.93vw;
      line-height: 8vw;
      letter-spacing: 0px;
      color: #000000;
    }

    p:last-of-type {
      font-weight: 400;
      font-size: 5.33vw;
      line-height: 6.13vw;
      letter-spacing: 0px;
      color: #000000;
      margin-left: 2.2vw;
    }
  }

  .bn-desc {
    position: relative;
    width: 100%;
    height: 3.73vw;
    font-weight: 400;
    font-size: 3.2vw;
    line-height: 3.73vw;
    color: #333333;
    margin-top: 1.07vw;
    margin-bottom: 6.4vw;
    text-align: justify;
    sup {
      position: absolute;
      top: 0.8vw;
      right: -1vw;
    }
    i {
      display: inline-block;
      width: 100%;
    }
    span:after {
      content:'';
      width: 100%;
      display: inline-block;
      overflow: hidden;
      height: 0;
    }
  }

  .bn-info {
    display: flex;
    width: 100%;
    font-weight: 400;
    font-size: 3.2vw;
    line-height: 3.73vw;
    letter-spacing: 0px;
    color: #000000;
    margin-top: 0.82vw;

    &:first-of-type {
      margin-top: 1.6vw;
    }

    span:first-child {
      flex: 48%;
      text-align: right;
    }
    span:nth-child(2) {
      flex: 4%;
      text-align: center;
    }
    span:last-child {
      flex: 48%;
    }
  }

  .bn-price {
    font-weight: 500;
    font-size: 6.4vw;
    line-height: 7.47vw;
    letter-spacing: 0px;
    color: #000000;
    margin-top: 8.53vw;

    .bn-price-num {
      font-family: 'HarmonyOSNumber';
    }

    .bn-price-desc {
      font-family: 'HarmonyOSNumber';
      text-decoration-line: line-through;
      margin-left: 1.07vw;
      font-size: 5.33vw;
      line-height: 5.33vw;
      letter-spacing: 0px;
      color: #777777;
    }
  }

  .bn-btn-flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 4.27vw;

    button {
      border-radius: 50px;
      background: #FFFFFF;
      border: 2px solid #FF5500;
      font-weight: 500;
      font-size: 3.73vw;
      line-height: 4.27vw;
      letter-spacing: 0px;
      color: #FF5500;
      padding: 2.13vw 3.2vw;
      cursor: pointer;
    }

    button:first-of-type {
      margin-right: 3.2vw;
    }
  }

  .bn-img-wrap {
    width: 100%;
    height: fit-content;
    padding: 0 14.27vw;
    box-sizing: border-box;
    margin-top: 4.27vw;

    img {
      width: 100%;
    }
  }
}

@hack: true; @import (reference) "/home/jenkins/agent/workspace/megrez-os-web/src/styles/base.less";