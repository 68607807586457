@import 'src/styles/base.less';


// .react-responsive-modal-modal  {
//   width: 90%;
//   padding: 0;
//   max-width: 90%;
// }
.customModal {
  width: 90%;
  padding: 0;
  max-width: 100px;
}
.video-modal-wrap{
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background: rgba(0, 0, 0, 0.48);
  justify-content: center;
  align-items: center;
  .video-modal{
    width: 70%;
    position: relative;
    .video-close-icon{
      position: absolute;
      top: 0;
      right: 0;
      z-index: 101;
      width: 1.94vw;
      cursor: pointer;
    }
    .video-react-big-play-button{
      visibility: hidden;
    }
  }
}


@media screen and (max-width: @xl-screen){
  .player-icon {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 7vw;
    height: 7vw;
    cursor: pointer;
    z-index: 10;
  }
}
@media screen and (min-width: @xl-screen){
  .player-icon {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 5.6vw;
    height: 5.6vw;
    cursor: pointer;
  }
}

@media screen and (max-width: @xl-screen){
  .player-container {
    position: relative;
    margin: 4.3vw 3.2vw 8.5vw 3.2vw;

    .video {
      border-radius: 16px;
    }
  }
}

@media screen and (min-width: @xl-screen){
  .player-container {
    position: relative;
  }
}


.player-container-bg {
  overflow: hidden;
  position: relative;
}

.player-bottom-content-container-bg {
  position: absolute;
  left: 0;
  bottom: 2.2vw;
  display: flex;
  justify-content: center;
  width: 100%;
  .content-bottom-bg {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.34) 0%, rgba(0, 0, 0, 0) 100%);
    width: 80%;
    color: #FFFFFF;
    font-size: 1.67vw;
    font-weight: 500;
    text-align: center;
    padding: 2.2vw 6.5vw;
    border-radius: 16px;
  }
}

.player-container-sm {
  overflow: hidden;
  margin: 4.3vw 3.2vw 8.5vw 3.2vw;
  .video-react-big-play-button{
    visibility: hidden;
  }

  .player-sm {
    border-radius: 16px;
  }

  .content-bottom-sm {
    font-weight: 400;
    font-size: 3.2vw;
    color: #000000;
    line-height: 4.8vw;
    margin-top: 4.3vw;
  }
  .video-modal{
    width: 100%;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animation-fade-in {
  opacity: 1;
  animation: fadeIn 0.4s ease-in-out;
}
@hack: true; @import (reference) "/home/jenkins/agent/workspace/megrez-os-web/src/styles/base.less";