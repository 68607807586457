.sp-root-lg {
  width: 100%;
  padding: 0 10.28vw 7.78vw ;
  box-sizing: border-box;
  overflow: hidden;

  .sp-title {
    font-weight: 700;
    font-size: 3.19vw;
    line-height: 3.75vw;
    letter-spacing: 0px;
    color: #000000;
    width: 100%;
    text-align: center;
    margin-bottom: 2.78vw;
  }

  .sp-flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
  }

  .sp-layer-1 {
    height: 28.47vw;
    margin-top: 2.78vw;

    .sp-layer-1-1 {
      width: 35.14vw;
    }

    .sp-layer-1-2 {
      width: 23.06vw;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .sp-layer-1-2-1 {
        height: calc((100% - 1.11vw) / 2);
      }

      .sp-layer-1-2-2 {
        height: calc((100% - 1.11vw) / 2);
      }
    }

    .sp-layer-1-3 {
      width: 19.03vw;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .sp-layer-1-3-1 {
        height: calc((100% - 1.11vw) / 2);
      }

      .sp-layer-1-3-2 {
        height: calc((100% - 1.11vw) / 2);
      }
    }
  }

  .sp-layer-2 {
    height: 13.89vw;
    margin-top: 1.11vw;

    .sp-layer-2-1 {
      width: 35vw;
    }

    .sp-layer-2-2 {
      width: 18.61vw;
    }

    .sp-layer-2-3 {
      width: 23.06vw;
    }
  }

  .sp-grid {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    border-radius: 1.67vw;
    background: radial-gradient(84.11% 84.11% at 16.98% 24.21%, rgba(255, 85, 0, 0.03) 17.67%, rgba(255, 85, 0, 0.06) 93.08%);

    .sp-row-flex {
      display: flex;
      p{
        margin-right: 0.56vw;
      }
    }

    .sp-h1 {
      font-weight: 700;
      font-size: 2.78vw;
      line-height: 3.75vw;
      letter-spacing: 0px;
      background: linear-gradient(180deg, #7C7C7C 10.32%, #000000 77.7%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.32);
      sup {
        background: linear-gradient(180deg, #7C7C7C 10.32%, #000000 77.7%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.32);
      }
    }

    .sp-h2 {
      font-weight: 500;
      font-size: 2.08vw;
      line-height: 2.43vw;
      letter-spacing: 0px;
      color: #000000;
      opacity: 0.8;
    }

    .sp-h3 {
      font-weight: 400;
      font-size: 1.25vw;
      line-height: 1.46vw;
      letter-spacing: 0px;
      color: #000000;
      opacity: 0.8;
    }
  }
  sup {
    top: -1.4em;
    font-size: 0.4em;
  }
}

.sp-root-sm {
  width: 100%;
  height: fit-content;
  padding: 0 3.2vw 6.4vw;
  box-sizing: border-box;
  overflow: hidden;

  .sp-title {
    font-weight: 700;
    font-size: 5.33vw;
    line-height: 6.13vw;
    letter-spacing: 0px;
    color: #000000;
    width: 100%;
    text-align: center;
    margin-bottom: 5.87vw;
  }

  .sp-flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    >div {
      box-sizing: border-box;
      height: 23.47vw;
      padding-left: 4.27vw;
      margin-bottom: 2.13vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      border-radius: 4.27vw;
      background: radial-gradient(84.11% 84.11% at 16.98% 24.21%, rgba(255, 85, 0, 0.03) 17.67%, rgba(255, 85, 0, 0.06) 93.08%);

      .sp-row-flex {
        display: flex;
        >:last-child{
          margin-left: 2.13vw;
        }
      }

      .sp-h1 {
        font-weight: 700;
        font-size: 4.8vw;
        line-height: 5.6vw;
        letter-spacing: 0px;
        background: linear-gradient(180deg, #7C7C7C 10.32%, #000000 77.7%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.32);
        sup {
          background: linear-gradient(180deg, #7C7C7C 10.32%, #000000 77.7%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.32);
        }
      }

      .sp-h2 {
        font-weight: 500;
        font-size: 3.73vw;
        line-height: 4.27vw;
        letter-spacing: 0px;
        color: #000000;
        opacity: 0.8;
      }

      .sp-h3 {
        font-weight: 400;
        font-size: 3.2vw;
        line-height: 3.73vw;
        letter-spacing: 0px;
        color: #000000;
      }
    }
  }

  .sp-layer-1 {
    margin-top: 5.87vw;
  }

  .sp-layer-1,.sp-layer-3 {
    >div:first-child {
      width: 53.87vw;
    }
    >div:last-child {
      width: 37.6vw;
    }
  }

  .sp-layer-2 {
    >div:first-child {
      width: 37.6vw;
    }
    >div:last-child {
      width: 53.87vw;
    }
  }
  .sp-layer-4 {
    >div{
      width: 45.87vw;
    }
  }
}

@hack: true; @import (reference) "/home/jenkins/agent/workspace/megrez-os-web/src/styles/base.less";