.ar-root-lg {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  h2 {
    margin-top: 7.78vw;
  }

  .ar-flex {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 4.93vw auto 4.44vw auto;

    .ar-left {
      width: 32vw;
      img {
        width: 100%;
      }
    }

    .ar-right {
      position: relative;
      width: 31vw;
      margin-left: 1.3vw;
      img {
        width: 100%;
      }
      .microphone {
        position: absolute;
        top: 11.18vw;
        left: 1.22vw;
        width: 9.56vw;
        img {
          width: 100%;
        }
      }
    }
  }
}

.ar-root-sm {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  h2 {
    margin-top: 7.78vw;
  }

  .ar-flex {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 4.93vw auto 4.44vw auto;

    .ar-left {
      width: 50.4vw;
      img {
        width: 100%;
      }
    }

    .ar-right {
      position: relative;
      width: 39.4vw;
      margin-top: 9.6vw;
      img {
        width: 100%;
      }
      .microphone {
        position: absolute;
        top: 14.96vw;
        left: 2.4vw;
        width: 10.67vw;
        img {
          width: 100%;
        }
      }
    }
  }
}

@hack: true; @import (reference) "/home/jenkins/agent/workspace/megrez-os-web/src/styles/base.less";