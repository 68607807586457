.aboutUs-container-lg{
  .aboutUs-pic-lg{
    width: 100vw;
    height: 30.56vw;
  }
  .aboutUs-pic-container {
    display: flex;
    justify-content: center;
  }
  .aboutUs-body-lg{
    max-width: 1440px;
    margin: 0 14.31vw;

    .aboutUs-product-lg{
      .aboutUs-product-title-lg{
        margin-top: 4.44vw;
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 48px;
        letter-spacing: 1px;
        color: #000000;
      }
      .aboutUs-product-subTitle-lg{
        margin-top: 4px;
        text-align: center;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 0px;
        color: #000000;
      }
      .aboutUs-product-content-lg{
        margin-top: 40px;
        white-space: pre-line;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0px;
        color: #000000;
        opacity: 0.8;
      }
    }
    .aboutUs-company-lg{
      .aboutUs-company-title-lg{
        margin-top: 120px;
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 48px;
        letter-spacing: 0px;
        color: #000000;
      }
      .aboutUs-company-content-lg{
        margin-top: 40px;
        margin-bottom: 40px;
        white-space: pre-line;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0px;
        color: #000000;
        opacity: 0.8;
      }
    }
  }
}
.aboutUs-container-sm{
  .aboutUs-pic-sm{
    width: 100%;
    height: auto;
  }
  .aboutUs-pic-container-sm {
    display: flex;
    justify-content: center;
  }
  .aboutUs-body-sm{
    margin: 0 3.2vw;
    max-width: 375px;

    .aboutUs-product-sm{
      .aboutUs-product-title-sm{
        margin-top: 43px;
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0px;
        color: #000000;
      }
      .aboutUs-product-subTitle-sm{
        margin-top: 4px;
        text-align: center;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0px;
        color: #000000;
      }
      .aboutUs-product-content-sm{
        margin-top: 16px;
        white-space: pre-line;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0px;
        color: #000000;
      }
    }
    .aboutUs-company-sm{
      .aboutUs-company-title-sm{
        margin-top: 17.07vw;
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0px;
        color: #000000;
      }
      .aboutUs-company-content-sm{
        margin-top: 16px;
        margin-bottom: 68px;
        white-space: pre-line;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0px;
        color: #000000;
      }
    }
  }
}

@hack: true; @import (reference) "/home/jenkins/agent/workspace/megrez-os-web/src/styles/base.less";