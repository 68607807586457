@import 'src/styles/base.less';

.nav-scroll-disabled {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}

@media screen and (min-width: @xxl-screen){
  .bar-lg {
    height: @nav-height-xxl;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    padding: 0 32px;
    background: #FFFFFF;

    .logo-lg {
      width: 120px;
      cursor: pointer;

      .logo-img-lg {
        width: 120px;
      }
    }

    .navigation-lg {
      display: flex;
      .navigation-item-lg {
        margin: 0px 24px;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        cursor: pointer;
      }

      .navigation-item-lg:last-child {
        margin-right: 0px;
      }
    }
  }
}

@media screen and (min-width: @xl-screen) and (max-width: @xxl-screen){
  .bar-lg {
    height: @nav-height-xl;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    padding: 0 32px;
    background: #FFFFFF;

    .logo-lg {
      width: 100px;
      cursor: pointer;

      .logo-img-lg {
        width: 100px;
      }
    }

    .navigation-lg {
      display: flex;
      .navigation-item-lg {
        margin: 0px 24px;
        font-weight: 400;
        font-size: 12px;
        color: #000000;
        cursor: pointer;
      }

      .navigation-item-lg:last-child {
        margin-right: 0px;
      }
    }
  }
}

@media screen and (min-width: @sm-screen){
  .bar-sm {
    height: @nav-height-lg;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    position: relative;
    background-color: #FFFFFF;

    .logo-sm {
      width: 120px;

      .logo-img-sm {
        width: 120px;
      }
    }

    .navigation-sm {
      width: 30px;
      height: 30px;
    }

    .nav-icon-sm {
      width: 30px;
      height: 30px;
      .nav-icon-img-sm {
        width: 30px;
        height: 30px;
      }
    }

    .navigation-item-container-sm {
      position: fixed;
      top: calc(@nav-height-lg + 1px);
      left: 0px;
      height: 100%;
      background: rgba(0, 0, 0, 0.48);
      z-index: 200;
      overflow: hidden;
      width: 100%;
    }

    .navigation-item-sm {
      font-weight: 400;
      font-size: 16px;
      line-height: 55px;
      height: 55px;
      color: #000000;
      padding-left: 16px;
      background: #FFFFFF;
  }

    .nav-divider-sm {
      padding: 0px 16px;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.04);
      background: #FFFFFF;
    }
  }
}

@media screen and (max-width: @sm-screen){
  .bar-sm {
    height: @nav-height-sm;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    position: relative;
    background-color: #FFFFFF;

    .logo-sm {
      width: 84px;

      .logo-img-sm {
        width: 84px;
      }
    }

    .navigation-sm {
      width: 24px;
      height: 24px;
    }

    .nav-icon-sm {
      width: 24px;
      height: 24px;
      .nav-icon-img-sm {
        width: 24px;
        height: 24px;
      }
    }

    .navigation-item-container-sm {
      position: fixed;
      top: calc(@nav-height-sm + 1px);
      left: 0px;
      height: 100%;
      background: rgba(0, 0, 0, 0.48);
      z-index: 152;
      overflow: hidden;
      width: 100%;
    }

    .navigation-item-sm {
      font-weight: 400;
      font-size: 14px;
      line-height: 47px;
      height: 47px;
      color: #000000;
      padding-left: 12px;
      background: #FFFFFF;
  }

    .nav-divider-sm {
      padding: 0px 12px;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.04);
      background: #FFFFFF;
    }
  }
}
@hack: true; @import (reference) "/home/jenkins/agent/workspace/megrez-os-web/src/styles/base.less";