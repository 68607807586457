.sync-root-lg {
  overflow: hidden;
  background: #FEFBFA;

  @media @xxl-to-more {
    .sync-main {
      width: 100%;
      height: calc(100vh - @nav-sub-height-xxl);
      overflow: hidden;
    }
    @reset-height: calc(100vh - @nav-sub-height-xxl - @xxl-to-more-h2-margin-top - @xxl-to-more-h2-line-height - @xxl-to-more-h3-line-height -  @xxl-to-more-h3-line-height);
    .sync-wrap {
      position: relative;
      width: 100%;
      height: @reset-height;
    }

    .sync-middle {
      position: absolute;
      z-index: 29;
      top: 50%;
      left: 50%;
      height: @reset-height;
    }

    .sync-common {
      position: absolute;
      top: 50%;
      left: 50%;
      height: @reset-height;
    }

    .sync-left-1 {
      .sync-common();
      z-index: 28;
    }

    .sync-left-2 {
      .sync-common();
      z-index: 27;
    }

    .sync-left-3 {
      .sync-common();
      z-index: 26;
    }

    .sync-left-4 {
      .sync-common();
      z-index: 25;
    }

    .sync-right-1 {
      .sync-common();
      z-index: 28;
    }

    .sync-right-2 {
      .sync-common();
      z-index: 27;
    }

    .sync-right-3 {
      .sync-common();
      z-index: 26;
    }

    .sync-right-4 {
      .sync-common();
      z-index: 25;
    }
  }

  @media @xl-to-xxl {
    .sync-main {
      width: 100%;
      height: calc(100vh - @nav-sub-height-xxl);
      overflow: hidden;
    }
    @reset-height: calc(100vh - @nav-sub-height-xxl - @xl-to-xxl-h2-margin-top - @xl-to-xxl-h2-line-height - @xl-to-xxl-h3-line-height -  @xl-to-xxl-h3-line-height);
    .sync-wrap {
      position: relative;
      width: 100%;
      height: @reset-height;
    }

    .sync-middle {
      position: absolute;
      z-index: 29;
      top: 50%;
      left: 50%;
      height: @reset-height;
    }

    .sync-common {
      position: absolute;
      top: 50%;
      left: 50%;
      height: @reset-height;
    }

    .sync-left-1 {
      .sync-common();
      z-index: 28;
    }

    .sync-left-2 {
      .sync-common();
      z-index: 27;
    }

    .sync-left-3 {
      .sync-common();
      z-index: 26;
    }

    .sync-left-4 {
      .sync-common();
      z-index: 25;
    }

    .sync-right-1 {
      .sync-common();
      z-index: 28;
    }

    .sync-right-2 {
      .sync-common();
      z-index: 27;
    }

    .sync-right-3 {
      .sync-common();
      z-index: 26;
    }

    .sync-right-4 {
      .sync-common();
      z-index: 25;
    }
  }
}

.sync-root-sm {
  overflow: hidden;
  background: #FEFBFA;

  .sync-main {
    width: 100%;
    height: 105.6vw;
    background-color: #fff;
    overflow: hidden;

    .sync-title {
      width: 100%;
      text-align: center;
      font-weight: 700;
      font-size: 5.33vw;
      line-height: 6.13vw;
      letter-spacing: 0px;
      color: #000000;
    }
    .swiper-container{
      width: 100%;
      height: auto;
    }
    .swiper-slide{
      width: fit-content;
    }
    .swiper-slide-active{
      .sync-pic{
        margin-top: 0;
        width: 64vw;
      }
    }
    .sync-pic {
      margin-top: 5vw;
      width: 60vw;
    }
  }
}

@hack: true; @import (reference) "/home/jenkins/agent/workspace/megrez-os-web/src/styles/base.less";