.res-root-lg {
  width: 100%;
  position: relative;
  padding: 0 7.78vw;
  box-sizing: border-box;
  overflow: hidden;

  .res-title {
    font-weight: 700;
    font-size: 3.19vw;
    line-height: 3.75vw;
    letter-spacing: 0px;
    color: #000000;
    width: 100%;
    text-align: center;
    margin-bottom: 1.11vw;
  }

  .res-desc {
    font-weight: 400;
    font-size: 1.39vw;
    line-height: 2.08vw;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 2.78vw;
    text-align: center;
  }

  img {
    width: 100%;
    max-width: 100%;
  }
}

.res-root-sm {
  width: 100%;
  position: relative;
  // padding: 8.53vw 0;
  box-sizing: border-box;
  overflow: hidden;

  .res-title {
    font-weight: 700;
    font-size: 5.33vw;
    line-height: 6.13vw;
    letter-spacing: 0px;
    color: #000000;
    width: 100%;
    text-align: center;
  }

  .res-desc {
    font-weight: 400;
    font-size: 3.2vw;
    line-height: 4.8vw;
    letter-spacing: 0px;
    color: #000000;
    margin-top: 2.13vw;
    margin-bottom: 4.27vw;
    text-align: center;
  }

  img {
    width: 100%;
    max-width: 100%;
  }
}

@hack: true; @import (reference) "/home/jenkins/agent/workspace/megrez-os-web/src/styles/base.less";