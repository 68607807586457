.br-root-lg {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;

  .br-title {
    font-weight: 700;
    font-size: 3.19vw;
    line-height: 3.75vw;
    letter-spacing: 0px;
    color: #000000;
    width: 100%;
    text-align: center;
    margin-bottom: 1.11vw;
  }

  .br-desc {
    font-weight: 400;
    font-size: 1.39vw;
    line-height: 2.08vw;
    letter-spacing: 0px;
    color: #000000;
    width: 100%;
    height: fit-content;
    text-align: center;
  }

  .br-bg {
    // position: absolute;
    // left: 0;
    // bottom: 0;
    // width: 100%;
    // height: fit-content;
    margin-top: 3vw;

    img {
      width: 100%;
      height: 39.38vw;
    }
  }
}

.br-root-sm {
  width: 100%;
  height: fit-content;
  position: relative;
  padding-bottom: 8.53vw;
  box-sizing: border-box;
  overflow: hidden;

  .br-title {
    font-weight: 700;
    font-size: 5.33vw;
    line-height: 6.13vw;
    letter-spacing: 0px;
    color: #000000;
    width: 100%;
    text-align: center;
  }

  .br-desc {
    font-weight: 400;
    font-size: 3.2vw;
    line-height: 4.8vw;
    letter-spacing: 0px;
    color: #000000;
    width: 100%;
    height: fit-content;
    text-align: center;
    margin-top: 2.13vw;
    margin-bottom: 4.27vw;
  }

  .br-bg {
    width: 100%;
    height: fit-content;

    img {
      background-color: #D0D0D0;
      width: 100%;
      height: 54.4vw;
    }
  }
}

@hack: true; @import (reference) "/home/jenkins/agent/workspace/megrez-os-web/src/styles/base.less";