@import 'src/styles/base.less';

.root{
  overflow: hidden;
  @media @xxl-to-more {
    .player {
      margin: 2.78vw 0;
    }
  }

  @media @xl-to-xxl {
    .player {
      margin: 2.78vw 0;
    }
  }

  @media @lg-to-xl {
    .player {
      margin: 2.78vw 0;
    }
  }

  @media @sm-to-lg {
    .player {
      margin: 6.4vw 0;
    }
  }

  @media @less-to-sm {
    .player {
      margin: 6.4vw 0;
    }
  }
}

