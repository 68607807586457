.reason-container-lg {
  width: 100%;
  height: 79.79vw;
  overflow: hidden;
}

.reason-header-lg {
  margin: 7.78vw auto 2.71vw;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 3.19vw;
  line-height: 3.75vw;
  letter-spacing: 0px;
  color: #000000;
}

.reason-cards-lg {
  margin: 0 auto;
  display: flex;
  width: 95.49vw;
  height: 62vw;
  justify-content: space-between;
  margin-top: 1.46vw;
}

.reason-title-lg {
  margin: 3.33vw 3.33vw 0.83vw;
  font-style: normal;
  font-size: 2.08vw;
  line-height: 2.43vw;
  font-weight: 500;
  background: linear-gradient(180deg, #7C7C7C 10.32%, #000000 69.41%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.32);

  sup {
    background: linear-gradient(180deg, #7C7C7C 10.32%, #000000 69.41%);
    background-clip: text;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.32);
  }
}

.reason-content-lg {
  margin: 0.83vw 3.33vw;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vw;
  line-height: 1.46vw;
  letter-spacing: 0px;
  color: #000;
  opacity: 0.8;
}

.reason-col-lg {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.reason-system-lg {
  width: 31.11vw;
  height: 39.65vw;
  background-size: cover;
}

.reason-focus-lg {
  width: 31.11vw;
  height: 30.21vw;
  background-size: cover;
  margin-top: 1.11vw;
}

.reason-ink-lg {
  width: 31.11vw;
  height: 35vw;
  background-size: cover;
}

.reason-pen-lg {
  width: 31.11vw;
  height: 21.46vw;
  background-size: cover;
  margin-top: 1.11vw;
}

.reason-source-lg {
  width: 31.11vw;
  height: 30.76vw;
  background-size: cover;
}

.reason-chip-lg {
  width: 31.11vw;
  height: 26.11vw;
  background-size: cover;
  margin-top: 1.11vw;
}

.reason-container-sm {
  width: 100%;
  height: 206.67vw;
  overflow: hidden;
}

.reason-header-sm {
  margin: 8.53vw auto 6.4vw;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 5.33vw;
  line-height: 6.13vw;
  letter-spacing: 0px;
  color: #000000;
}

.reason-cards-sm {
  margin: 6.4vw auto 0;
  display: flex;
  width: 93.87vw;
  height: 177.07vw;
  justify-content: space-between;
}

.reason-title-sm {
  margin: 4.27vw 4.27vw 2.13vw;
  font-style: normal;
  font-weight: 500;
  font-size: 4.27vw;
  line-height: 5.07vw;
  letter-spacing: 0px;
  background: linear-gradient(180deg, #7C7C7C 10.32%, #000000 69.41%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0.43px 0.43px rgba(0, 0, 0, 0.32);

  sup {
    background: linear-gradient(180deg, #7C7C7C 10.32%, #000000 69.41%);
    background-clip: text;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.32);
  }
}

.reason-content-sm {
  margin: 0 4.27vw;
  font-style: normal;
  font-weight: 400;
  font-size: 3.2vw;
  line-height: 3.73vw;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.8;
}

.reason-col-sm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.reason-system-sm {
  width: 45.87vw;
  height: 97.87vw;
  padding-top: 1.33vw;
  background-size: cover;
}

.reason-focus-sm {
  width: 45.87vw;
  height: 55.2vw;
  background-size: cover;
  margin-top: 1.11vw;
}

.reason-ink-sm {
  width: 45.87vw;
  height: 57.87vw;
  background-size: cover;
}

.reason-pen-sm {
  width: 45.87vw;
  height: 35.47vw;
  background-size: cover;
  margin-top: 1.11vw;
}

.reason-source-sm {
  width: 45.87vw;
  height: 59.73vw;
  background-size: cover;
}

.reason-chip-sm {
  width: 45.87vw;
  height: 39.47vw;
  background-size: cover;
  margin-top: 1.11vw;
}

@hack: true; @import (reference) "/home/jenkins/agent/workspace/megrez-os-web/src/styles/base.less";