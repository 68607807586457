@font-face {
  font-family: "IconFont";
  src: url('~@/assets/styles/icons/fonts/icomoon.woff') format('woff'),
    url('~@/assets/styles/icons/fonts/icomoon.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: "HarmonyOSNumber";
  src: url('~@/assets/styles/icons/fonts/harmonyOS_number.ttf') format('truetype');
  font-display: swap;
}

.icon(@code, @name) {
  .megrez-icon-@{name}::before {
    font-family: "IconFont";
    content: "@{code}";
  }
}

.icon('\e902', question);
.icon('\e901', explain);
.icon('\e904', practice);
.icon('\e900', induction);
.icon('\e903', analogy);
.icon('\e905', correct);


@sm-screen: 640px;
@lg-screen: 1024px;
@xl-screen: 1200px;
@xxl-screen: 1440px;

@less-to-sm: ~"screen and (max-width: @{sm-screen})";
@sm-to-lg: ~"screen and (min-width: @{sm-screen}) and (max-width: @{lg-screen})";
@lg-to-xl: ~"screen and (min-width: @{lg-screen}) and (max-width: @{xl-screen})";
@xl-to-xxl: ~"screen and (min-width: @{xl-screen}) and (max-width: @{xxl-screen})";
@xxl-to-more: ~"screen and (min-width: @{xxl-screen})";

@nav-height-xxl: 58px;
@nav-height-xl: 48px;
@nav-height-lg: 65px;
@nav-height-sm: 48px;

@nav-sub-height-xxl: 64px;
@nav-sub-height-xl: 90px;
@nav-sub-height-lg: 90px;
@nav-sub-height-sm: 73px;

.visibility-1 {
  visibility: visible;
}
.visibility-0 {
  visibility: hidden;
}

.min-width {
  min-width: @lg-screen;
  overflow: auto;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 150;
  width: 100%;
  background: rgba(255,255,255,0.9);
}

.h2-common {
  text-align: center;
  width: 100%;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 700;
  margin-bottom: 0px;
}

.h3-common {
  text-align: center;
  font-weight: 400;
  letter-spacing: 0px;
  color: #000000;
}

@xxl-to-more-h2-line-height: 3.75vw;
@xxl-to-more-h2-margin-top: 5.56vw;
@xxl-to-more-h2-font-size: 3.19vw;

@xxl-to-more-h3-line-height: 2.08vw;
@xxl-to-more-h3-margin-top: 0.69vw;
@xxl-to-more-h3-font-size: 1.39vw;
@xxl-to-more-h3-margin-bottom: 1.39vw;

@media @xxl-to-more {
  h2 {
    .h2-common();
    font-size: @xxl-to-more-h2-font-size;
    margin-top: @xxl-to-more-h2-margin-top;
    line-height: @xxl-to-more-h2-line-height;
  }

  h3 {
    .h3-common();
    font-size: @xxl-to-more-h3-font-size;
    margin-top: @xxl-to-more-h3-margin-top;
    margin-bottom: @xxl-to-more-h3-margin-bottom;
    line-height: @xxl-to-more-h3-line-height;
  }
}

@xl-to-xxl-h2-line-height: 3.75vw;
@xl-to-xxl-h2-margin-top: 5.56vw;
@xl-to-xxl-h2-font-size: 3.19vw;

@xl-to-xxl-h3-line-height: 2.08vw;
@xl-to-xxl-h3-margin-top: 0.69vw;
@xl-to-xxl-h3-font-size: 1.39vw;
@xl-to-xxl-h3-margin-bottom: 1.39vw;

@media @xl-to-xxl {
  h2 {
    .h2-common();
    font-size: @xl-to-xxl-h2-font-size;
    margin-top: @xl-to-xxl-h2-margin-top;
    line-height: @xl-to-xxl-h2-line-height;
  }

  h3 {
    .h3-common();
    font-size: @xxl-to-more-h3-font-size;
    margin-top: @xxl-to-more-h3-margin-top;
    margin-bottom: @xxl-to-more-h3-margin-bottom;
    line-height: @xxl-to-more-h3-line-height;
  }
}

@lg-to-xl-h2-line-height: 6.1vw;
@lg-to-xl-h2-margin-top: 8.53vw;
@lg-to-xl-h2-font-size: 5.3vw;

@lg-to-xl-h3-line-height: 2.08vw;
@lg-to-xl-h3-margin-top: 0.69vw;
@lg-to-xl-h3-font-size: 1.39vw;
@lg-to-xl-h3-margin-bottom: 1.39vw;

@media @lg-to-xl {
  h2 {
    .h2-common();

    font-size: @lg-to-xl-h2-font-size;
    line-height: @lg-to-xl-h2-line-height;
    margin-top:@lg-to-xl-h2-margin-top;
  }

  h3 {
    .h3-common();
    font-size: @lg-to-xl-h3-font-size;
    margin-top: @lg-to-xl-h3-margin-top;
    margin-bottom: @lg-to-xl-h3-margin-bottom;
    line-height: @lg-to-xl-h3-line-height;
  }
}

@sm-to-lg-h2-line-height: 6.1vw;
@sm-to-lg-h2-margin-top: 8.53vw;
@sm-to-lg-h2-font-size: 5.3vw;

@sm-to-lg-h3-line-height: 4.8vw;
@sm-to-lg-h3-margin-top: 2.13vw;
@sm-to-lg-h3-font-size: 3.2vw;
@sm-to-lg-h3-margin-bottom: 3.73vw;

@media @sm-to-lg {
  h2 {
    .h2-common();

    font-size: @sm-to-lg-h2-font-size;
    line-height: @sm-to-lg-h2-line-height;
    margin-top:@sm-to-lg-h2-margin-top;
  }

  h3 {
    .h3-common();
    font-size: @sm-to-lg-h3-font-size;
    margin-top: @sm-to-lg-h3-margin-top;
    margin-bottom: @sm-to-lg-h3-margin-bottom;
    line-height: @sm-to-lg-h3-line-height;
  }
}

@less-to-sm-h2-line-height: 6.1vw;
@less-to-sm-h2-margin-top: 8.53vw;
@less-to-sm-h2-font-size: 5.3vw;

@less-to-sm-h3-line-height: 4.8vw;
@less-to-sm-h3-margin-top: 2.13vw;
@less-to-sm-h3-font-size: 3.2vw;
@less-to-sm-h3-margin-bottom: 3.73vw;

@media @less-to-sm {
  h2 {
    .h2-common();

    font-size: @less-to-sm-h2-font-size;
    line-height: @less-to-sm-h2-line-height;
    margin-top:@less-to-sm-h2-margin-top;
  }

  h3 {
    .h3-common();
    font-size: @less-to-sm-h3-font-size;
    margin-top: @less-to-sm-h3-margin-top;
    margin-bottom: @less-to-sm-h3-margin-bottom;
    line-height: @less-to-sm-h3-line-height;
  }
}

.opacity-0 {
  opacity: 0;
}

.scroll-disabled {
  overflow: hidden;
}

/* layout settings */

.fit {
  width: 100%;
  height: 100%;
}

/* text color settings */

.link {
  cursor: pointer;
  user-select: none;
  color: #1890ff;
}

.text-primary {
  color: #1890ff;
}

.text-success {
  color: #52c41a
}

.text-info {
  color: #909399
}

.text-warning {
  color: #fa8c16
}

.text-danger {
  color:#f5222d
}

.text-tip {
  color: #1890ff;
  font-size: 12px;
  margin-left: 8px;
}

/* text style settings */

.bold-text {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-small {
  font-size: 0.5em;
}

.text-nowrap {
  white-space: nowrap;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

/* background style settings */

.bg-level-0 td {
  background-color: #d9f7be !important;
}

.bg-level-1 td {
  background-color: #f0f0f0 !important;
}


.video-custom {
  width: 80%;
  padding: 0;
  max-width: 80%;
}

.video-close-icon {
  background-color: white;
}

button:focus {
  outline: 0;
}
i {
  font-style: normal;
}
