@import 'src/styles/base.less';

.opacity-0 {
  opacity: 0;
}

.file-container-bg {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  height: 58vw;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .file-main {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .file-left {
    color: #000000;
    .title {
      font-weight: 700;
      font-size: 46px;
      color: #000000;
    }

    .content {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      margin: 16px 0 48px;
    }
    .file-type {
      .file-type-title {
        font-weight: 400;
        font-size: 0.83vw;
        color: #333333;
      }
      .icon-group {
        margin-top: 0.42vw;
        display: flex;
        .icon-item {
          font-weight: 400;
          font-size: 0.69vw;
          line-height: 0.83vw;
          color: #333333;
          text-align: center;
          img {
            width: 2.2vw;
            height: 2.2vw;
            display: block;
          }
        }

        .icon-item:not(:first-child) {
          margin: 0 0.83vw;
        }
        .icon-item:first-child {
          margin-right: 0.83vw;
        }
      }
    }

  }

  .file-right-bg {
    display: flex;
    position: relative;
    .phone-bg {
      width: 9vw;
      margin-right: 60px;
      margin-left: 8vw;
      position: relative;
      z-index: 1;
      img {
        width: 9vw;
        position: absolute;
        bottom: 0px;
      }
    }
    .equip-bg {
      width: 27vw;
      z-index: 1;
      img {
        width: 27vw;
      }
    }

    .arrow-bg {
      position: absolute;
      width: 14vw;
      left: calc(8vw + 4.5vw - 1vw);
      top: 6.2vw;
      opacity: 1;
      z-index: 0;
      img {
        width: 14vw;
      }
    }
  }
}

.file-container-sm {
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(159vw - @nav-sub-height-sm);
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .title {
    font-weight: 700;
    font-size: 5.3vw;
    color: #000000;
  }

  .content {
    margin: 3vw 0 6vw;
    text-align: center;
    font-weight: 400;
    font-size: 3.2vw;
    line-height: 4.8vw;
    letter-spacing: 0px;
    color: #000000;
  }
  .file-img-container-sm {
    display: flex;
    justify-content: center;
    position: relative;
    .phone-sm {
      width: 20vw;
      margin-right: 32px;
      position: relative;
      z-index: 1;
      img {
        width: 20vw;
        position: absolute;
        bottom: 0px;
      }
    }
    .equip-sm {
      width: 57.6vw;
      z-index: 1;
      img {
        width: 57.6vw;
      }
    }

    .arrow-sm {
      position: absolute;
      width: 23vw;
      left: 10vw;
      top: 16vw;
      opacity: 1;
      img {
        width: 23vw;
      }
    }
  }

  .file-types-sm {
    margin-top: 5.6vw;
    font-weight: 400;
    font-size: 2.7vw;
    color: #FFFFFF;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;

    img {
      width: 4.8vw;
      height: 4.8vw;
      margin: 0.8vw
    }
  }
}

@hack: true; @import (reference) "/home/jenkins/agent/workspace/megrez-os-web/src/styles/base.less";