.news-lg{
  .news-header{
    margin-top: 4.44vw;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 2.64vw;
    line-height: 3.13vw;
    letter-spacing: 0px;
    color: #000000;
  }
  .news-cards{
    margin: 0 0 5.56vw 2.22vw;
    width: 97.78vw;
    margin-top: 3.33vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    cursor: pointer;
    .news-card{
      margin-bottom: 1.11vw;
      margin-right: 1.11vw;
      width: 31.11vw;
      height: 32.92vw;
      border-radius: 1.67vw;
      background: #fff;
      border: 1px solid #d9d9d9;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .news-card-img{
        width: 100%;
        height: 18.61vw;
        overflow: hidden;
        background-size: cover;
      }
      .news-card-tags{
        display: flex;
        flex-direction: row;
        margin: 2.22vw 2.22vw 1.11vw;
        .news-card-tag{
          height: 1.88vw;
          margin-right: 0.83vw;
          border-radius: 0.97vw;
          background: #EEEEEE;
          padding: 0 0.83vw;
          text-align: center;
          font-style: normal;
          font-weight: 400;
          font-size: 1.11vw;
          line-height: 1.88vw;
          letter-spacing: 0px;
          color: #303030;
        }
      }

      @news-card-title-line-height: 1.2;
      @news-card-title-font-size: 1.66vw;
      @news-card-title-line-clamp: 2;

      .news-card-title{
        margin: 0 2.22vw;
        display: -webkit-box;
        -webkit-line-clamp: @news-card-title-line-clamp;
        -webkit-box-orient:vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-style: normal;
        font-weight: 400;
        font-size: @news-card-title-font-size;
        line-height: @news-card-title-line-height;
        height: calc(@news-card-title-font-size * @news-card-title-line-height * @news-card-title-line-clamp);
        letter-spacing: 0px;
        color: #000000;
      }
      .news-card-footer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 1.67vw 2.22vw 2.22vw;
        .news-card-date{
          font-style: normal;
          font-weight: 500;
          font-size: 1.11vw;
          line-height: 1.32vw;
          letter-spacing: 0px;
          color: #333333;
          opacity: 0.8;
        }
        .news-card-source{
          width: 7.8vw;
          white-space: nowrap;
          overflow: hidden;
          text-align: right;
          text-overflow: ellipsis;
          font-style: normal;
          font-weight: 500;
          font-size: 0.97vw;
          line-height: 1.11vw;
          letter-spacing: 0px;
          color: #666666;
          opacity: 0.8;
        }
      }
    }
  }
}

.news-sm{
  .news-header{
    margin-top: 8.53vw;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 5.33vw;
    line-height: 6.13vw;
    letter-spacing: 0px;
    color: #000000;
  }
  .news-cards{
    margin: 0 auto 10.67vw;
    width: 93.87vw;
    margin-top: 8.53vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .news-card{
      margin-bottom: 2.13vw;
      width: 45.87vw;
      border-radius: 4.27vw;
      background: #fff;
      border: 1px solid #d9d9d9;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .news-card-img{
        width: 100%;
        height: 32vw;
        overflow: hidden;
        background-size: cover;
      }
      .news-card-tags{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 4.27vw 2.13vw;
        .news-card-tag{
          height: 5.87vw;
          margin-top: 2.13vw;
          margin-right: 2.13vw;
          border-radius: 3.73vw;
          background: #EEEEEE;
          padding: 0 2.67vw;
          text-align: center;
          font-style: normal;
          font-weight: 400;
          font-size: 3.2vw;
          line-height: 5.87vw;
          letter-spacing: 0px;
          color: #303030;
        }
      }
      @news-card-title-line-height: 1.2;
      @news-card-title-font-size: 4vw;
      @news-card-title-line-clamp: 2;
      .news-card-title{
        margin: 4.27vw 4.27vw 0;
        display: -webkit-box;
        -webkit-line-clamp: @news-card-title-line-clamp;
        -webkit-box-orient:vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-style: normal;
        font-weight: 400;
        font-size: @news-card-title-font-size;
        line-height: @news-card-title-line-height;
        height: calc(@news-card-title-font-size * @news-card-title-line-height * @news-card-title-line-clamp);
        letter-spacing: 0px;
        color: #000000;
      }
      .news-card-footer{
        margin: 3.2vw 4.27vw 4.27vw;
        .news-card-date{
          font-style: normal;
          font-weight: 500;
          font-size: 3.2vw;
          line-height: 3.73vw;
          letter-spacing: 0px;
          color: #333333;
          opacity: 0.8;
        }
        .news-card-source{
          margin-top: 2.13vw;
          width: 26vw;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-style: normal;
          font-weight: 500;
          font-size: 3.2vw;
          line-height: 3.73vw;
          letter-spacing: 0px;
          color: #666666;
          opacity: 0.8;
        }
      }
    }
  }
}

@hack: true; @import (reference) "/home/jenkins/agent/workspace/megrez-os-web/src/styles/base.less";