.fd-root-lg {
  overflow: hidden;
  background: #FEFBFA;

  @media @xxl-to-more {
    .fd-main {
      overflow: hidden;
      width: 100%;
      @fd-height:  calc(100vh - @nav-sub-height-xxl - @xxl-to-more-h2-margin-top - @xxl-to-more-h2-line-height - @xxl-to-more-h3-line-height - @xxl-to-more-h3-margin-top - @xxl-to-more-h3-margin-bottom);
      .fd-wrap {
        position: relative;
        // width: fit-content;
        height: @fd-height;
      }

      .fd-pad {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: @fd-height;
        opacity: 0;
      }

      .fd-paper {
        position: absolute;
        height: 100%;
        opacity: 0;
        top: 50%;
        left: 50%;
      }


      .fd-paper-active {
        position: absolute;
        opacity: 1;
        top: 50%;
        left: 50%;
        height: 100%;
        z-index: 99;
      }
    }
  }
  @media @xl-to-xxl {
    .fd-main {
      width: 100%;
      overflow: hidden;
      @fd-height:  calc(100vh - @nav-sub-height-xxl - @xl-to-xxl-h2-margin-top - @xl-to-xxl-h2-line-height - @xl-to-xxl-h3-line-height - @xl-to-xxl-h3-margin-top - @xl-to-xxl-h3-margin-bottom);
      .fd-wrap {
        position: relative;
        height: @fd-height;
      }

      .fd-pad {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: @fd-height;
        opacity: 0;
      }

      .fd-paper {
        position: absolute;
        height: 100%;
        opacity: 0;
        top: 50%;
        left: 50%;
      }


      .fd-paper-active {
        position: absolute;
        opacity: 1;
        top: 50%;
        left: 50%;
        height: 100%;
        z-index: 99;
      }
    }
  }
}

.fd-root-sm {
  overflow: hidden;
  background: #FEFBFA;
  .fd-main {
    width: 100%;


    @media @lg-to-xl {
      @fd-height:  calc(100vh - @nav-sub-height-xl - @lg-to-xl-h2-margin-top - @lg-to-xl-h2-line-height - @lg-to-xl-h3-line-height - @lg-to-xl-h3-margin-top - @lg-to-xl-h3-margin-bottom);
      .fd-wrap {
        position: relative;
        height: @fd-height;
      }
      .fd-pad {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%)  scale(0.8);
        height: @fd-height;
        opacity: 0;
      }
    }
    @media @sm-to-lg {
      @fd-height:  calc(100vh - @nav-sub-height-lg - @sm-to-lg-h2-margin-top - @sm-to-lg-h2-line-height - @sm-to-lg-h3-line-height - @sm-to-lg-h3-margin-top - @sm-to-lg-h3-margin-bottom);
      .fd-wrap {
        position: relative;
        height: @fd-height;
      }

      .fd-pad {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%)  scale(0.8);
        height: @fd-height;
        opacity: 0;
      }
    }

    @media @less-to-sm {
      @fd-height:  calc(100vh - @nav-sub-height-sm - @less-to-sm-h2-margin-top - @less-to-sm-h2-line-height - @less-to-sm-h3-line-height - @less-to-sm-h3-margin-top - @less-to-sm-h3-margin-bottom);
      .fd-wrap {
        position: relative;
        height: @fd-height;
      }

      .fd-pad {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.6);
        height: @fd-height;
        opacity: 0;
      }
    }

    .fd-paper {
      position: absolute;
      height: 100%;
      opacity: 0;
      top: 50%;
      left: 50%;
    }

    .fd-paper-active {
      position: absolute;
      opacity: 1;
      top: 50%;
      left: 50%;
      height: 100%;
      z-index: 99;
    }
  }
}

@hack: true; @import (reference) "/home/jenkins/agent/workspace/megrez-os-web/src/styles/base.less";