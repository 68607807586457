.cs-root-lg {
  width: 100%;
  overflow: hidden;

  .cs-title {
    font-weight: 700;
    font-size: 3.19vw;
    line-height: 3.75vw;
    letter-spacing: 0px;
    color: #000000;
    width: 100%;
    text-align: center;
    margin-bottom: 4.44vw;
  }

  .cs-bg {
    position: relative;
    width: 100vw;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }

    p {
      position: absolute;
      top: 18.19vw;
      left: 14.31vw;
      font-weight: 900;
      font-size: 4.17vw;
      line-height: 4.86vw;
      letter-spacing: 0px;
      color: #FFFFFF;
      z-index: 99;
    }
  }
}

.cs-root-sm {
  width: 100%;
  background: #FFFFFF;
  position: relative;
  padding: 0 3.2vw;
  box-sizing: border-box;
  overflow: hidden;

  .cs-title {
    font-weight: 700;
    font-size: 5.33vw;
    line-height: 6.13vw;
    letter-spacing: 0px;
    color: #000000;
    width: 100%;
    text-align: center;
    margin-bottom: 4.27vw;
  }

  .cs-bg {
    position: relative;
    width: 100%;
    height: fit-content;
    margin-top: 4.27vw;

    img {
      width: 100%;
      border-radius: 16px;
    }

    p {
      position: absolute;
      top: 8.53vw;
      left: 8vw;
      font-weight: 900;
      font-size: 7.47vw;
      line-height: 8.8vw;
      letter-spacing: 0px;
      color: #FFFFFF;
      height: fit-content;
    }
  }
}

@hack: true; @import (reference) "/home/jenkins/agent/workspace/megrez-os-web/src/styles/base.less";