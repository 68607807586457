.dd-root-lg {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 3.33vw;

  .dd-title {
    font-weight: 700;
    font-size: 3.19vw;
    line-height: 3.75vw;
    letter-spacing: 0px;
    color: #000000;
    text-align: center;
  }

  .dd-flex {
    display: flex;
    flex-grow: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 3.33vw;

    img {
      width: 100%;
    }

    .dd-column-1 {
      width: 31.11vw;
    }

    .dd-column-2 {
      width: 27.08vw;
      margin-left: 1.11vw;
      display: flex;
      flex-direction: column;

      img:nth-of-type(2) {
        margin-top: 1.11vw;
      }
    }

    .dd-column-3 {
      width: 15vw;
      margin-left: 1.11vw;
    }
  }
}

.dd-root-sm {
  background-color: white;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 8.53vw;

  .dd-title {
    font-weight: 700;
    font-size: 5.33vw;
    line-height: 6.13vw;
    letter-spacing: 0px;
    color: #000000;
    text-align: center;
  }

  div {
    box-sizing: border-box;
  }

  .dd-flex {
    display: flex;
    flex-grow: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 56.27vw;
    margin-top: 6.4vw;
    padding: 0 2.93vw;

    img {
      width: 100%;
      height: 100%;
    }

    .dd-column-1 {
      width: calc(100% - 21.87vw - 2.13vw);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .dd-column-1-1 {
        width: 100%;
        height: calc((100% - 2.13vw) / 2)
      }

      .dd-column-1-2 {
        width: 100%;
        height: calc((100% - 2.13vw) / 2);
        display: flex;
        justify-content: space-between;
        align-items: center;

        .dd-column-1-2-1, .dd-column-1-2-2 {
          width: calc((100% - 2.13vw) / 2);
          height: 100%;
        }
      }
    }

    .dd-column-2 {
      width: 21.87vw;
      height: 100%;
      margin-left: 2.13vw;
    }
  }
}

@hack: true; @import (reference) "/home/jenkins/agent/workspace/megrez-os-web/src/styles/base.less";