.ant-modal-body {
  padding-top: 16px;
  padding-bottom: 16px;
}

.ant-modal-confirm-content {
  max-width: 100% !important;
}

.ant-form-item-explain-error {
  font-size: 12px;
  padding-top: 4px;
}

.ant-form-item-extra {
  color: #1890ff !important;
  font-size: 12px !important;
  padding-top: 4px;
}

.ant-transfer .ant-transfer-list:last-of-type .ant-transfer-list-content-item {
  background-color: rgb(255, 230, 230);
}

.ant-transfer .ant-transfer-list:last-of-type .ant-transfer-list-body-search-wrapper {
  display: none;
}

.ant-collapse-header {
  padding-left: 0 !important;
  align-items: center !important;
}

.ant-collapse-content-box {
  padding: 0 !important;
}

.ant-steps-item-active .ant-steps-item-title {
  color: #1890ff !important;
}

.ant-steps-item-active .ant-steps-icon-dot {
  will-change: transform;
  transition: transform 0.3s ease-in-out;
  transform: scale(1.6);
}

td+.__ant-table-cell-no-left-border {
  border-inline-end: 0 !important;
}

.ant-tooltip-inner {
  white-space: pre-wrap;
}
