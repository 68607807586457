.footer-scroll-disabled {
  overflow: hidden;
}

.footer-lg {
  box-sizing: border-box;
  width: 100%;
  height: fit-content;
  overflow: hidden;
  background: #222222;

  .footer-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 42px 100px 40px;

    .footer-title {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0px;
      color: #FFFFFF;
    }
    .footer-title-focus{
      text-align: center;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 0.8;
    }

    .footer-content {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0px;
      color: #FFFFFF;
      margin-top: 18px;
      text-decoration: none;
    }

    .footer-left {
      display: flex;
      .footer-left-layer{
        position: relative;
        margin-left: 106px;
        &:first-of-type{
          margin-left: 26px;
        }
        img {
          position: absolute;
          top: 0;
          left: -30px;
          width: 22px;
        }
      }
      a {
        display: block;
        text-decoration: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0px;
        color: #FFFFFF;
        margin-top: 10px;
        cursor: pointer;
        &:first-of-type {
          margin-top: 18px;
        }
      }
    }

    .footer-right {
      .footer-right-layer {
        position: relative;
        margin-top: 40px;

        &:first-of-type {
          margin-top: 0;
        }

        img {
          position: absolute;
          top: 0;
          left: -30px;
          width: 22px;
        }

        .footer-qr {
          position: relative;
          top: 0;
          left: 0;
          width: 123px;
          margin-top: 12px;
        }
      }
    }
  }

  .footer-copyright {
    padding: 32px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.6;
    text-align: left;
    border-top: 1px solid rgba(255, 255, 255, 0.24);
  }
}

.footer-sm {
  box-sizing: border-box;
  width: 100%;
  height: fit-content;
  overflow: hidden;
  background: #222222;

  .footer-purchase-wrap {
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid rgba(255, 255, 255, 0.32);

    .footer-purchase {
      padding: 24px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0px;
        color: #FFFFFF;
      }

      img {
        width: 10px;
        transition: transform 0.3s ease-in-out;
      }
    }

    .footer-purchase-items {
      display: none;
      padding: 0 12px 8px;

      p {
        width: 100%;
        text-align: left;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 0.8;
        padding: 8px 0;
      }
    }
    .footer-purchase-items-display {
      display: block;
    }
  }

  .footer-layers {
    padding: 17px 0 28px 0;

    .footer-layer {
      position: relative;
      padding: 9px 12px 9px 38px;

      .footer-title {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0px;
        color: #FFFFFF;
      }

      .footer-content {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0px;
        color: #FFFFFF;
        margin-top: 6px;
      }

      img {
        position: absolute;
        top: 8px;
        left: 12px;
        width: 20px;
      }

      .footer-wechat {
        position: relative;
        top: 0;
        left: 0 !important;
        width: 32px;
        margin-top: 12px;
      }
    }
  }


  .footer-copyright {
    padding: 10px 12px;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.6;
    text-align: left;
    border-top: 1px solid rgba(255, 255, 255, 0.24);
  }

  .footer-wechat-mask {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background: rgba(0, 0, 0, 0.48);
    justify-content: center;
    align-items: center;

    .footer-wechat-wrap {
      width: 220px;
      height: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img:first-of-type {
        width: 100%;
      }

      img:last-of-type {
        width: 32px;
        margin-top: 24px;
      }
    }
  }

  .footer-wechat-mask-open {
    display: flex;
  }
}

@hack: true; @import (reference) "/home/jenkins/agent/workspace/megrez-os-web/src/styles/base.less";