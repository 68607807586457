.opacity-0 {
  opacity: 0;
}

.container {
  overflow: hidden;
  position: relative;
}

.content-left {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10vw;
  .title {
    font-weight: 700;
    font-size: 3.2vw;
    letter-spacing: 0px;
    color: #000000;
    // color: #FFFFFF;
  }

  .content {
    margin-top: 1.1vw;
    font-weight: 400;
    font-size: 1.4vw;
    line-height: 2.1vw;
    color: #000000;
    // color: #FFFFFF;
  }
}

.content-center {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .title {
    font-weight: 700;
    font-size: 5.3vw;
    letter-spacing: 0px;
    color: #000000;
    // color: #FFFFFF;
  }

  .content {
    margin-top: 2.1vw;
    font-weight: 400;
    font-size: 3.2vw;
    color: #000000;
    // color: #FFFFFF;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animation-fade-in {
  opacity: 1;
  animation: fadeIn 0.4s ease-in-out;
}