.sps-root-lg {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  h2 {
    margin-top: 3.26vw;
  }

  h3 {
    text-align: center;
    font-weight: 400;
    letter-spacing: 0px;
    color: #000000;
    font-size: 1.39vw;
    margin-top: 0.69vw;
    margin-bottom: 0;
    line-height: 2.08vw;

  }

  .sps-flex {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 5.76vw auto 5.62vw auto;

    .sps-left {
      width: 44.7vw;
      margin-right: 1vw;
      img {
        width: 100%;
      }
    }

    .sps-right {
      width: 41vw;
      margin-left: 1vw;
      img {
        width: 100%;
      }
    }
  }
}
.sps-root-sm {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  h2 {
    margin-top: 3.26vw;
  }

  h3 {
    text-align: center;
    font-weight: 400;
    letter-spacing: 0px;
    color: #000000;
    font-size: 3.2vw;
    margin-top: 0.69vw;
    margin-bottom: 0;
    line-height: 4.8vw;
    width: 70vw;
  }

  .sps-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 5.76vw auto 5.62vw auto;

    .sps-top {
      width: 85.87vw;
      margin-bottom: 2.26vw;
      img {
        width: 100%;
      }
    }

    .sps-bottom {
      width: 85.87vw;
      img {
        width: 100%;
      }
    }
  }
}
@hack: true; @import (reference) "/home/jenkins/agent/workspace/megrez-os-web/src/styles/base.less";