.system-container-lg{
  background: #FBF3F0;
  overflow: hidden;
  @media @xxl-to-more{
    .system-main {
      width: 100vw;
      height: calc(100vh - @nav-sub-height-xxl);
      overflow: hidden;
      .system-header-lg{
        .system-highlight-lg{
          display: inline-block;
          color: #FF5500;
          background-size: 100% 30%;
          background-position: center 90%;
          background-repeat: no-repeat;
        }
      }

      .system-body-lg{
        height: calc(100vh - @nav-sub-height-xxl - @xxl-to-more-h2-margin-top - @xxl-to-more-h2-line-height);
        display: flex;
        flex-direction: row;
        align-items: center;
        .system-card-container-lg{
          width: 43vw;
          .system-card{
            background-color: #fff;
            color: #000;
            width: 10.97vw;
            height: 9.1vw;
            border-radius: 1.11vw;
            border: 0.06vw solid #C8C9C9;
            .system-card-flex{
              display: flex;
              align-items: center;
              margin-top: 1.11vw;
              margin-left: 1.11vw;
              margin-bottom: 0.5vw;
            }
            .system-title{
              margin-left: 0.56vw;
              font-style: normal;
              font-weight: 700;
              font-size: 1.11vw;
              line-height: 1.32vw;
              letter-spacing: 0px;
            }
            .system-content{
              margin: auto 1.11vw;
              font-style: normal;
              font-weight: 400;
              font-size: 0.83vw;
              line-height: 1.11vw;
              letter-spacing: 0px;
              opacity: 0.8;
            }
          }
          .system-card-layer1-lg{
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .system-card-layer2-lg{
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
            >img{
              transform: rotate(180deg);
            }
          }
          .system-arrow-layer{
            width: 100%;
            height: 4.44vw;
            display: flex;
            justify-content: space-between;
            align-items: center;
            :first-child{
              margin-left: 4.1vw;
              transform: rotate(270deg);
            }
            :last-child{
              margin-right: 4.1vw;
              transform: rotate(90deg);
            }
          }
          .system-arrow-lg{
            width: 2.78vw;
            height: 0.97vw;
          }
        }
        .system-left{
          width: 50%;
          .system-pic-hidden{
            position: relative;
            width: 40vw;
            margin: 0 auto;
            max-width: calc(1.3 * ( 100vh - @nav-sub-height-xxl - @xxl-to-more-h2-margin-top - @xxl-to-more-h2-line-height ) );
            height: fit-content;
            overflow: hidden;
            .system-mask{
              height: 100%;
              position: absolute;
              z-index: 100;
              &:first-of-type{
                top: 0;
                left: 0;
                transform: rotate(180deg);
              }
              &:last-of-type{
                top: 0;
                right: 0;
              }
            }
            .system-pic-container-lg{
              position: relative;
              display: flex;
              margin: auto 0;
              height: fit-content;
              transform: translateX(18%);
              img{
                max-width: calc(0.8 * ( 100vh - @nav-sub-height-xxl - @xxl-to-more-h2-margin-top - @xxl-to-more-h2-line-height ) );
                max-height: calc(100vh - @nav-sub-height-xxl - @xxl-to-more-h2-margin-top - @xxl-to-more-h2-line-height);
                width: 24.8vw;
                height: 30.67vw;
              }
            }
          }
        }
      }
    }
  }
  @media @xl-to-xxl{
    .system-main {
      width: 100vw;
      height: calc(100vh - @nav-sub-height-xl);
      overflow: hidden;
      .system-header-lg{
        .system-highlight-lg{
          display: inline-block;
          color: #FF5500;
          background-size: 100% 30%;
          background-position: center 90%;
          background-repeat: no-repeat;
        }
      }

      .system-body-lg{
        height: calc(100vh - @nav-sub-height-xl - @xl-to-xxl-h2-margin-top - @xl-to-xxl-h2-line-height);
        display: flex;
        flex-direction: row;
        align-items: center;
        .system-card-container-lg{
          width: 43vw;
          .system-card{
            background-color: #fff;
            color: #000;
            width: 10.97vw;
            height: 9.1vw;
            border-radius: 1.11vw;
            border: 0.06vw solid #C8C9C9;
            .system-card-flex{
              display: flex;
              align-items: center;
              margin-top: 1.11vw;
              margin-left: 1.11vw;
              margin-bottom: 0.5vw;
            }
            .system-title{
              margin-left: 0.56vw;
              font-style: normal;
              font-weight: 700;
              font-size: 1.11vw;
              line-height: 1.32vw;
              letter-spacing: 0px;
            }
            .system-content{
              margin: auto 1.11vw;
              font-style: normal;
              font-weight: 400;
              font-size: 0.83vw;
              line-height: 1.11vw;
              letter-spacing: 0px;
              opacity: 0.8;
            }
          }
          .system-card-layer1-lg{
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .system-card-layer2-lg{
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
            >img{
              transform: rotate(180deg);
            }
          }
          .system-arrow-layer{
            width: 100%;
            height: 4.44vw;
            display: flex;
            justify-content: space-between;
            align-items: center;
            :first-child{
              margin-left: 4.1vw;
              transform: rotate(270deg);
            }
            :last-child{
              margin-right: 4.1vw;
              transform: rotate(90deg);
            }
          }
          .system-arrow-lg{
            width: 2.78vw;
            height: 0.97vw;
          }
        }
        .system-left{
          width: 50%;
          .system-pic-hidden{
            position: relative;
            width: 40vw;
            margin: 0 auto;
            max-width: calc(1.3 * ( 100vh - @nav-sub-height-xl - @xl-to-xxl-h2-margin-top - @xl-to-xxl-h2-line-height ) );
            height: fit-content;
            overflow: hidden;
            .system-mask{
              height: 100%;
              position: absolute;
              z-index: 100;
              &:first-of-type{
                top: 0;
                left: 0;
                transform: rotate(180deg);
              }
              &:last-of-type{
                top: 0;
                right: 0;
              }
            }
            .system-pic-container-lg{
              position: relative;
              display: flex;
              margin: auto 0;
              height: fit-content;
              transform: translateX(18%);
              img{
                max-width: calc(0.8 * ( 100vh - @nav-sub-height-xl - @xl-to-xxl-h2-margin-top - @xl-to-xxl-h2-line-height ) );
                max-height: calc(100vh - @nav-sub-height-xl - @xl-to-xxl-h2-margin-top - @xl-to-xxl-h2-line-height);
                width: 24.8vw;
                height: 30.67vw;
              }
            }
          }
        }
      }
    }
  }
  i{
    font-size: 1.52vw;
  }
}

.system-container-sm{
  width: 100vw;
  height: 189.33vw;
  overflow: hidden;
  background: #FEFBFA;
}
.system-header-sm{
  margin: 7.78vw auto auto;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 5.33vw;
  line-height: 8vw;
  letter-spacing: 0px;
  color: #000000;
}

.system-highlight-sm{
  display: inline-block;
  color: #FF5500;
  background-size: 100% 30%;
  background-position: center 90%;
  background-repeat: no-repeat;
}
.system-body-sm{
  height: 148.8vw;
  .system-card-container-sm{
    width: 93.87vw;
    margin: 8.53vw auto;
    position: relative;
    .system-card{
      width: 23.47vw;
      height: 7.47vw;
      border-radius: 2.13vw;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
      background: #fff;
      color: #666;
      .system-card-flex{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      i{
        font-size: 3.2vw;
      }
      .system-title{
        margin-left: 1.07vw;
        font-style: normal;
        font-weight: 500;
        font-size: 3.2vw;
        line-height: 3.73vw;
        letter-spacing: 0px;
      }
      .system-content{
        display: none;
      }
    }
    .system-card-left-sm{
      display: flex;
      justify-content: left;
      margin-bottom: 6.4vw;
      >div{
        margin-right: 7.46vw;
      }
      >div:last-child{
        margin-right: 0;
      }
    }
    .system-card-right-sm{
      display: flex;
      justify-content: right;
      >div{
        margin-left: 7.46vw;
      }
      >div:first-child{
        margin-left: 0;
      }
    }
    .system-arrow-sm{
      width: 3.2vw;
      height: 2.13vw;
    }
    .system-arrow-1-sm{
      position: absolute;
      top: 2.67vw;
      left: 25.6vw;
    }
    .system-arrow-2-sm{
      position: absolute;
      top: 2.67vw;
      left: 56.53vw;
    }
    .system-arrow-3-sm{
      position: absolute;
      transform: rotate(90deg);
      top: 9.07vw;
      left: 77.6vw;
      transform-origin: top left;
    }
    .system-arrow-4-sm{
      position: absolute;
      transform: rotate(180deg);
      top: 16.53vw;
      left: 34.13vw;
    }
    .system-arrow-5-sm{
      position: absolute;
      transform: rotate(180deg);
      top: 16.53vw;
      left: 65.07vw;
    }
    .system-arrow-6-sm{
      position: absolute;
      transform: rotate(270deg);
      top: 9.07vw;
      left: 8.86vw;
      transform-origin: top right;
    }
  }
  .system-pic-container-sm{
    margin: 0 11.2vw;
    .system-pic-title-sm{
      margin-bottom: 1.07vw;
      font-style: normal;
      font-weight: 700;
      font-size: 3.73vw;
      line-height: 4.27vw;
      letter-spacing: 0px;
      color: #000000;
    }
    .system-pic-content-sm{
      height: 7.47vw;
      font-style: normal;
      font-weight: 400;
      font-size: 3.2vw;
      line-height: 3.73vw;
      letter-spacing: 0px;
      color: #000000;
    }
    .system-pic-wrap-sm{
      position: relative;
      left: -3vw;
      top: 3vw;
      width: 82.8vw;
      height: fit-content;
      position: relative;
    }
    .system-pic-sm{
      width: 100%;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
@hack: true; @import (reference) "/home/jenkins/agent/workspace/megrez-os-web/src/styles/base.less";