@import 'src/styles/base.less';

@media screen and (min-width: @xxl-screen) {
  .navbar-sub-lg {
    box-sizing: border-box;
    // position: sticky;
    // top: 0;
    // z-index: 150;
    height: @nav-height-xxl;
    width: 100%;
    background: rgba(255,255,255,0.9);
    backdrop-filter: saturate(180%) blur(16px);
    height: @nav-sub-height-xxl;

    .navbar-sub-main {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 0 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .navbar-sub-title {
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 0px;
        color: #333333;
      }

      nav {
        ul {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin: 0;

          li {
            list-style: none;
            padding: 8px 0;
            margin-left: 24px;
          }

          a {
            display: block;
            text-decoration: none;
            font-weight: 300;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0px;
            color: #000000;
            cursor: pointer;
          }

          .navbar-sub-active {
            border-bottom: 2px solid #FF5500;

            a {
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              letter-spacing: 0px;
              color: #FF5500;
            }
          }

          button {
            border-radius: 50px;
            background: #FF5500;
            padding: 8px 20px;
            margin-left: 24px;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0px;
            color: #FFFFFF;
            border: none;
            outline: none;
            cursor: pointer;
          }

          .navbar-sub-active {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0px;
            color: #FF5500;
          }
        }
      }
    }
  }
}

@media screen and (max-width: @xxl-screen) and (min-width: @xl-screen) {
  .navbar-sub-lg {
    box-sizing: border-box;
    // position: sticky;
    // top: 0;
    // z-index: 150;
    height: @nav-height-xl;
    width: 100%;
    background: rgba(255,255,255,0.9);
    backdrop-filter: saturate(180%) blur(16px);
    height: @nav-sub-height-xxl;

    .navbar-sub-main {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 0 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .navbar-sub-title {
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 0px;
        color: #333333;
      }

      nav {
        ul {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin: 0;

          li {
            list-style: none;
            padding: 8px 0;
            margin-left: 24px;
          }

          a {
            display: block;
            text-decoration: none;
            font-weight: 300;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0px;
            color: #000000;
            cursor: pointer;
          }

          .navbar-sub-active {
            border-bottom: 2px solid #FF5500;

            a {
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              letter-spacing: 0px;
              color: #FF5500;
            }
          }

          button {
            border-radius: 50px;
            background: #FF5500;
            padding: 8px 20px;
            margin-left: 24px;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0px;
            color: #FFFFFF;
            border: none;
            outline: none;
            cursor: pointer;
          }

          .navbar-sub-active {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0px;
            color: #FF5500;
          }
        }
      }
    }
  }
}

@media screen and (max-width: @sm-screen){
  .navbar-sub-sm {
    height: @nav-sub-height-sm;
    box-sizing: border-box;
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: saturate(180%) blur(16px);

    .navbar-sub-main {
      box-sizing: border-box;
      width: 100%;
      padding: 0px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;

      .navbar-sub-title {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0px;
        color: #333333;
      }

      button {
        border-radius: 13.33vw;
        background: #FF5500;
        padding: 6px 16px;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0px;
        color: #FFFFFF;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }

      nav {
        ul {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 0 12px;
          margin: 0;
          height: calc(@nav-sub-height-sm - 48px);

          li {
            list-style: none;
            margin-right: 16px;
          }

          a {
            display: block;
            text-decoration: none;
            font-weight: 300;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0px;
            color: #000000;
            cursor: pointer;
          }

          .navbar-sub-active {
            border-bottom: 2px solid #FF5500;

            a {
              font-weight: 500;
              font-size: 12px;
              line-height: 14px;
              letter-spacing: 0px;
              color: #FF5500;
            }
          }
        }
      }
  }
}

@media screen and (min-width: @sm-screen) and (max-width: @lg-screen) {
  .navbar-sub-sm {
    height: @nav-sub-height-lg;
    box-sizing: border-box;
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: saturate(180%) blur(16px);

    .navbar-sub-main {
      box-sizing: border-box;
      width: 100%;
      padding: 0px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;

      .navbar-sub-title {
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0px;
        color: #333333;
      }

      button {
        border-radius: 13.33vw;
        background: #FF5500;
        padding: 6px 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0px;
        color: #FFFFFF;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }

      nav {
        ul {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 0 16px;
          margin: 0;
          height: calc(@nav-sub-height-lg - 60px);

          li {
            list-style: none;
            margin-right: 16px;
          }

          a {
            display: block;
            text-decoration: none;
            font-weight: 300;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: 0px;
            color: #000000;
            cursor: pointer;
          }

          .navbar-sub-active {
            border-bottom: 2px solid #FF5500;

            a {
              font-weight: 500;
              font-size: 16px;
              line-height: 16px;
              letter-spacing: 0px;
              color: #FF5500;
            }
          }
        }
      }
  }
}

@media screen and (min-width: @lg-screen) and (max-width: @xl-screen) {
  .navbar-sub-sm {
    height: @nav-sub-height-xl;
    box-sizing: border-box;
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: saturate(180%) blur(16px);

    .navbar-sub-main {
      box-sizing: border-box;
      width: 100%;
      padding: 0px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;

      .navbar-sub-title {
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0px;
        color: #333333;
      }

      button {
        border-radius: 13.33vw;
        background: #FF5500;
        padding: 6px 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0px;
        color: #FFFFFF;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }

      nav {
        ul {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 0 16px;
          margin: 0;
          height: calc(@nav-sub-height-xl - 60px);

          li {
            list-style: none;
            margin-right: 16px;
          }

          a {
            display: block;
            text-decoration: none;
            font-weight: 300;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: 0px;
            color: #000000;
            cursor: pointer;
          }

          .navbar-sub-active {
            border-bottom: 2px solid #FF5500;

            a {
              font-weight: 500;
              font-size: 16px;
              line-height: 16px;
              letter-spacing: 0px;
              color: #FF5500;
            }
          }
        }
      }
  }
}

@hack: true; @import (reference) "/home/jenkins/agent/workspace/megrez-os-web/src/styles/base.less";