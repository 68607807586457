.specs-container-lg{
  width: 100%;
  .specs-card-container{
    .specs-card{
      margin: 0 auto;
      padding: 64px 0;
      width: 79.44vw;
      display: flex;
      overflow: hidden;
      border-bottom: 1px solid #ebebeb;
      .specs-title{
        margin-left: 16.11vw;
        width: 16.11vw;
        height: auto;
        font-style: normal;
        font-weight: 500;
        font-size: 1.67vw;
        line-height: 1.94vw;
        letter-spacing: 0px;
        color: #000000;
      }
      .specs-content{
        .specs-content-text{
          margin-bottom: 1.67vw;
          white-space: pre-line;
          font-style: normal;
          font-weight: 400;
          font-size: 1.11vw;
          line-height: 1.36vw;
          letter-spacing: 0px;
          color: #000000;
        }
        .specs-content-text:last-child{
          margin-bottom: 0;
        }
      }
    }
    .specs-card:last-child{
      border: 0;
    }
  }
  .specs-comment{
    padding: 4.4vw 10.3vw;
    white-space: pre-line;
    background-color: #f5f5f5 ;
    font-style: normal;
    font-weight: 400;
    font-size: 0.83vw;
    line-height: 1.32vw;
    letter-spacing: 0px;
    color: #333333;
  }
}
.specs-product-pic-lg{
  width: 18.54vw;
  height: 19.44vw;
}

.specs-container-sm{
  width: 100%;
  .specs-card-container{
    .specs-card{
      margin: 0 auto;
      width: 93.87vw;
      padding: 8.53vw 0;
      overflow: hidden;
      border-bottom: 1px solid #ebebeb;
      .specs-title{
        margin-bottom: 6.4vw;
        font-style: normal;
        font-weight: 500;
        font-size: 4.27vw;
        line-height: 5.07vw;
        letter-spacing: 0px;
        color: #000000;
      }
      .specs-content{
        .specs-content-text{
          margin-bottom: 4.27vw;
          white-space: pre-line;
          font-style: normal;
          font-weight: 400;
          font-size: 3.2vw;
          line-height: 3.73vw;
          letter-spacing: 0px;
          color: #000000;
        }
        .specs-content-text:last-child{
          margin-bottom: 0;
        }
      }
    }
    .specs-card:last-child{
      border: 0;
    }
  }
  .specs-comment{
    padding: 12.8vw 3.2vw;
    white-space: pre-line;
    background-color: #f5f5f5 ;
    font-style: normal;
    font-weight: 400;
    font-size: 3.2vw;
    line-height: 3.73vw;
    letter-spacing: 0px;
    color: #333333;
  }
}
.specs-product-pic-sm{
  display: block;
  margin: 0 auto;
  width: 44.27vw;
  height: 46.4vw;
}
@hack: true; @import (reference) "/home/jenkins/agent/workspace/megrez-os-web/src/styles/base.less";