@import 'src/styles/base.less';
.accordion-title-common {
  text-align: center;
  width: 100%;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 700;
}

.accordion-desc-common {
  width: 100%;
  display: flex;
  justify-content: center;

  .accordion-main {
    border-radius: 12px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    display: flex;
    justify-content: center;
  }

  .accordion-desc {
    margin-top: 1.38vw;
  }

  .accordion-desc-item {
    width: 33%;
    display: flex;

    .desc-content-container {
      width: calc(100% - 1px);
      font-weight: 400;
      letter-spacing: 0px;
      color: #000000;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    .desc-divider {
      width: 1px;
      background: #EEEEEE;
    }

    .desc-tip {
      font-size: 55%;
      cursor: pointer;
    }
  }
}

.accordion-container-common {
  position: relative;
  overflow: hidden;
}

.accordion-item-container-common {
  display: inline-block;
  scroll-snap-align: start;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  flex-shrink: 0;
  height: 100%;
}

.left-arrow-common {
  z-index: 50;
  top: 45%;
  position: absolute;
  opacity: 0;
  animation: scale 1s infinite ease-in;
  cursor: pointer;
}

.right-arrow-common {
  z-index: 50;
  top: 45%;
  position: absolute;
  opacity: 0;
  animation: scale 1s infinite ease-in;
  cursor: pointer;
}
@keyframes scale{
  0%{
    transform: scale(0.8);
  }
  50%{
    transform: scale(1);
  }
  100%{
    transform: scale(0.8);
  }
}

@media screen and (min-width: @xl-screen){
  .accordion-title {
    .accordion-title-common();

    font-size: 3.2vw;
    margin-top: 7.8vw;
  }

  .accordion-desc {
    .accordion-desc-common();

    .accordion-main {
      width: 60%;
      height: 6.1vw;
    }

    .desc-content-container {
      font-size: 1.4vw;
      line-height: 1.6vw;
    }

    .desc-divider {
      height: 2.8vw;
      margin: 1.7vw 0;
    }

    margin-top: 3.3vw;
  }

  .accordion-container {
    .accordion-container-common();
    height: 25vw;
    margin: 2.8vw 0;
    .accordion-img {
      width: 44.44vw;
      margin-left: 2.22vw;
    }
  }

  .accordion-item-container {
    .accordion-item-container-common();

    margin-left: 2.1vw;

    .accordion-item-img {
      height: 100%;
      margin-right: 2.1vw;
    }
  }

  .scroll-container {
    overflow-x: scroll;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    box-sizing: content-box;
    height: 28.5vw;
  }

  .left-arrow {
    .left-arrow-common();

    width: 4.4vw;
    left: 2.8vw;
    .left-img {
      width: 100%;
    }
  }

  .right-arrow {
    .right-arrow-common();

    width: 4.4vw;
    right: 2.8vw;
    .right-img {
      width: 100%;
    }
  }
}

@media screen and (max-width: @xl-screen){
  .accordion-title {
    .accordion-title-common();

    font-size: 5.3vw;
    line-height: 6.1vw;
    margin-top: 8.5vw;
  }

  .accordion-desc{
    .accordion-desc-common();
    .accordion-main {
      width: 100%;
      margin: 0px 4.67vw;
      height: 16vw;
    }

    .desc-content-container {
      font-size: 3.73vw;
    }

    .desc-divider {
      height: calc(16vw - 20px);
      margin: 10px 0;
    }

    margin-top: 6.4vw;
  }

  .accordion-container {
    .accordion-container-common();
    height: 55.2vw;
    margin: 6.4vw 0;
    .accordion-img {
      width: 77.87vw;
      margin-left: 3.2vw;
    }
    .accordion-img-last {
      padding-right: 2.22vw;
    }
  }

  .scroll-container {
    overflow-x: scroll;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    box-sizing: content-box;
    height: 35vw;
  }

  .accordion-item-container{
    .accordion-item-container-common();
    height: 100%;
    margin-left: 12px;

    .accordion-item-img {
      height: 100%;
      margin-right: 12px;
    }
  }
}

.accordion-main-container {
  z-index: 20;
  width: 100%;
  display: inline-flex;
}

.accordion-container:hover {
  .right-arrow {
    opacity: 1;
    transform: scale(1);
  }

  .left-arrow {
    opacity: 1;
    transform: scale(1);
  }
}
.swiper-button-disabled {
  visibility: hidden;
}
.snap-x {
  scroll-snap-type: x var(--tw-scroll-snap-strictness);
}

@hack: true; @import (reference) "/home/jenkins/agent/workspace/megrez-os-web/src/styles/base.less";