@import 'src/styles/base.less';


@media screen and (max-width: @xl-screen){
  .comments-container {
    background: #F5F5F5;
    padding: 12.8vw 3.2vw;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .comments-main {
      margin-bottom: 8.53vw;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 3.3vw;
      line-height: 3.73vw;
      letter-spacing: 0px;
      color: #333333;
      opacity: 0.8;
      padding: 1vw 0;
    }
  }

}

@media screen and (min-width: @xl-screen){
  .comments-container {
    background: #F5F5F5;
    padding: 4.4vw 10.3vw;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .comments-main {
      margin-bottom: 3.33vw;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 0.83vw;
      line-height: 1.32vw;
      letter-spacing: 0px;
      color: #333333;
      opacity: 0.8;
      padding: 0.1vw 0;
    }
  }
}

.selected-content{
  color: #000000 !important;
  font-weight: 500 !important;
}
@hack: true; @import (reference) "/home/jenkins/agent/workspace/megrez-os-web/src/styles/base.less";