body {
  margin: 0;
  font-family: 'PingFang SC', 'helvetica neue', 'hiragino sans gb', 'arial',
    'microsoft yahei ui',' microsoft yahei', 'simsun', 'sans-serif' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p, span {
  margin: 0;
}
