.lp-root-lg {
  overflow: hidden;
  background: #FEFBFA;

  .lp-main {
    width: 100%;
    height: calc(100vh - @nav-sub-height-xxl);
    display: flex;
    justify-content: center;
    align-items: center;

    .lp-visible {
      opacity: 1;
    }

    .lp-not-visible {
      opacity: 0;
    }

    .lp-left {
      width: 50%;
      height: 100%;
      position: relative;

      .lp-pad {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto auto;
        width: 35.91vw;
        max-width: calc(0.85 * ( 100vh - @nav-sub-height-xxl ) );
      }

      .lp-pan-combine {
        .lp-pan {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto 0;
          width: 2.64vw;
        }
      }
    }

    .lp-right {
      width: 50%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .lp-text-area {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        // height: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .lp-title {
          font-weight: 700;
          font-size: 3.19vw;
          line-height: 3.75vw;
          letter-spacing: 0px;
          color: #000000;
        }

        .lp-desc {
          font-weight: 400;
          font-size: 1.39vw;
          line-height: 2.08vw;
          letter-spacing: 0px;
          color: #000000;
          margin-top: 1.11vw;
        }
      }
    }
  }
}

@media @lg-to-xl {
  .lp-root-sm {
    overflow: hidden;
    background: #FEFBFA;

    .lp-main {
      width: 100%;
      height: calc(100vh - @nav-height-lg);
      min-height: 2.4vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .lp-visible {
        opacity: 1;
      }

      .lp-not-visible {
        opacity: 0;
      }

      .lp-bottom {
        width: 100%;
        height: 75%;
        box-sizing: border-box;
        position: relative;

        .lp--pad-portrait & {
          height: 70%;
        }

        .lp-pad {
          position: absolute;
          top: 10%;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 77.87vw;

          .lp--pad-portrait & {
            top: 0;
            width: 67.86vw;
          }
        }

        .lp-pan-combine {
          position: absolute;
          top: 0%;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 77.87vw;
          height: 100%;

          .lp--pad-portrait & {
            width: 67.86vw;
          }

          .lp-pan {
            position: absolute;
            top: 20%;
            right: 0vw;
            margin: 0 auto;
            width: 4.8vw;

            .lp--pad-portrait & {
              width: 3.9vw;
            }
          }
        }
      }

      .lp-top {
        width: 100%;
        height: 25%;
        position: relative;

        .lp--pad-portrait & {
          height: 30%;
        }

        .lp-text-area {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 77.87vw;
          margin: 0 auto;
          height: fit-content;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .lp-title {
            font-weight: 700;
            font-size: 5.33vw;
            line-height: 6.13vw;
            letter-spacing: 0px;
            color: #000000;
          }

          .lp-desc {
            font-weight: 400;
            font-size: 3.73vw;
            line-height: 5.6vw;
            letter-spacing: 0px;
            color: #000000;
            margin-top: 3.2vw;
          }
        }
      }
    }
  }

}

@media @sm-to-lg {
  .lp-root-sm {
    overflow: hidden;
    background: #FEFBFA;

    .lp-main {
      width: 100%;
      height: calc(100vh - @nav-height-lg);
      min-height: 2.4vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .lp-visible {
        opacity: 1;
      }

      .lp-not-visible {
        opacity: 0;
      }

      .lp-bottom {
        width: 100%;
        height: 75%;
        box-sizing: border-box;
        position: relative;

        .lp--pad-portrait & {
          height: 70%;
        }

        .lp-pad {
          position: absolute;
          top: 10%;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 45vw;

          .lp--pad-portrait & {
            top: 0;
            width: 67.86vw;
          }
        }

        .lp-pan-combine {
          position: absolute;
          top: 0%;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 77.87vw;
          height: 100%;

          .lp--pad-portrait & {
            width: 67.86vw;
          }

          .lp-pan {
            position: absolute;
            top: 10%;
            right: 0vw;
            margin: 0 auto;
            width: 4vw;

            .lp--pad-portrait & {
              width: 3.9vw;
            }
          }
        }
      }

      .lp-top {
        width: 100%;
        height: 25%;
        position: relative;

        .lp--pad-portrait & {
          height: 30%;
        }

        .lp-text-area {
          position: absolute;
          top: 6vw;
          left: 0;
          right: 0;
          width: 77.87vw;
          margin: 0 auto;
          height: fit-content;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .lp-title {
            font-weight: 700;
            font-size: 4vw;
            line-height: 5vw;
            letter-spacing: 0px;
            color: #000000;
          }

          .lp-desc {
            font-weight: 400;
            font-size: 2.5vw;
            line-height: 3.5vw;
            letter-spacing: 0px;
            color: #000000;
            margin-top: 2vw;
          }
        }
      }
    }
  }

}

@media @less-to-sm {
  .lp-root-sm {
    overflow: hidden;
    background: #FEFBFA;

    .lp-main {
      width: 100%;
      height: calc(100vh - @nav-height-sm);
      min-height: 2.4vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .lp-visible {
        opacity: 1;
      }

      .lp-not-visible {
        opacity: 0;
      }

      .lp-bottom {
        width: 100%;
        height: 75%;
        box-sizing: border-box;
        position: relative;

        .lp--pad-portrait & {
          height: 70%;
        }

        .lp-pad {
          position: absolute;
          top: 10%;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 77.87vw;

          .lp--pad-portrait & {
            top: 0;
            width: 67.86vw;
          }
        }

        .lp-pan-combine {
          position: absolute;
          top: 0%;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 77.87vw;
          height: 100%;

          .lp--pad-portrait & {
            width: 67.86vw;
          }

          .lp-pan {
            position: absolute;
            top: 20%;
            right: 0vw;
            margin: 0 auto;
            width: 4.8vw;

            .lp--pad-portrait & {
              width: 3.9vw;
            }
          }
        }
      }

      .lp-top {
        width: 100%;
        height: 25%;
        position: relative;

        .lp--pad-portrait & {
          height: 30%;
        }

        .lp-text-area {
          position: absolute;
          top: 8.5vw;
          left: 0;
          right: 0;
          width: 77.87vw;
          margin: 0 auto;
          height: fit-content;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .lp-title {
            font-weight: 700;
            font-size: 5.33vw;
            line-height: 6.13vw;
            letter-spacing: 0px;
            color: #000000;
          }

          .lp-desc {
            font-weight: 400;
            font-size: 3.73vw;
            line-height: 5.6vw;
            letter-spacing: 0px;
            color: #000000;
            margin-top: 3.2vw;
          }
        }
      }
    }
  }

}



@hack: true; @import (reference) "/home/jenkins/agent/workspace/megrez-os-web/src/styles/base.less";